'use strict';

angular.module('kljDigitalLibraryApp')
    .service('StatuesService', ["$q", "common", "appConfig","$rootScope",
        function($q, common, appConfig,$rootScope) {

            this.totalStatues = [];
            var journalBaseUrl = '/api/case_journals';
            var that = this;
            this.getAll = function(body, appendRecords) {
                 console.log("inside ststues.service getAll()", body);
                return common.callApi('POST',
                    journalBaseUrl + '/journalSearch',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    body
                )

                .then(function(statues) {
                        if(!$rootScope.offlineApp){
                             if (undefined === appendRecords || false === appendRecords || '' === appendRecords) {
                            that.totalStatues = [];
                            that.totalStatues = that.totalStatues.concat(statues.data.list);
                            } else {
                                that.totalStatues = that.totalStatues.concat(statues.data.list);
                            }
                            return {statues:that.totalStatues,totalCount:statues.data.count,status:statues.data.status};
                        }
                        else{

                             if (undefined === appendRecords || false === appendRecords || '' === appendRecords) {
                            that.totalStatues = [];
                            that.totalStatues = that.totalStatues.concat(statues.data);
                            } else {
                                that.totalStatues = that.totalStatues.concat(statues.data);
                            }
                            return that.totalStatues;
                        }
                       
                    })
                    .catch(function(err) {
                        console.log(err, "=error===")
                        return{status:err.data.status,message:err.data.message};
                        return ;
                    })
            }


            this.getDetails = function(id, body) {

                return common.callApi('POST',
                    journalBaseUrl + '/getCaseJournal/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,

                    body)


                .then(function(response) {

                    return response.data;
                })
            }


        }
    ]);